<template>
  <modal-wrapper :id="id" hide-footer hide-header size="md">
    <template #title>
      Cambio profilo
    </template>
    <template #body>
      <div>
        <div
          :class="profileClassesPerson"
          @click="changeProfile(null)"
        >
          <div class="card--profile__img">
            <div :style="profileImgStyle"></div>
          </div>
          <div class="card--profile__user">
            <span>{{userNameGenerated}}</span>
            <span>{{userEmail}}</span>
          </div>
          <div class="card--profile__action">
            <span>
              <font-awesome-icon :icon="selectIcon(null)"/>
            </span>
          </div>
        </div>
        <div v-if="userProfiles.length > 0" class="mt-2">
          <div
            :class="profileClassesCompanies(profile)"
            v-for="(profile, index) in userProfiles"
            :key="index"
            @click="changeProfile(profile)"
          >
            <div class="card--profile__img">
              <div :style="profilesImgStyle(profile.additionalInfos.images)"></div>
            </div>
            <div class="card--profile__user">
              <span>{{profile.additionalInfos.company.companyName}}</span>
              <span>{{profile.labelRelationType}}</span>
            </div>
            <div class="card--profile__action">
              <span>
                <font-awesome-icon :icon="selectIcon(profile)"/>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import { isNotEmpty, isNotExist } from '@/utils/validators';
import { createPublicUri } from '@/utils/utilities';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ModalProfileSwitch',
  components: {
    ModalWrapper,
  },
  props: {
    id: String,
    onModalHide: Function,
    profileImgStyle: String,
    currentName: String,
    userName: String,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      userPlaceholder: require('@/assets/img/user-img_placeholder.png'),
      // eslint-disable-next-line global-require
      companyPlaceholder: require('@/assets/img/company-img_placeholder.png'),
    };
  },
  computed: {
    isLoadedData() {
      return this.$store.getters['subject/isLoadedData'];
    },
    userEmail() {
      return this.$store.getters['auth/currentEmail'];
    },
    userProfiles() {
      return this.$store.getters['subject/currentUserProfiles'] || [];
    },
    entityType() {
      if (this.isLoadedData) {
        if (this.$store.getters['subject/currentPerson'].personId) {
          return 'person';
        }
        return 'company';
      }
      return '';
    },
    entityId() {
      if (this.isLoadedData) {
        if (this.$store.getters['subject/currentPerson'].personId) {
          return this.$store.getters['subject/currentPerson'].personId;
        }
        return this.$store.getters['subject/currentCompany'].companyId;
      }
      return '';
    },
    profileClassesPerson() {
      if (this.$store.getters['subject/isPersonalProfile']) {
        return 'card card--profile card--profile--active cursor-pointer';
      }
      return 'card card--profile cursor-pointer';
    },
    userNameGenerated() {
      return this.userName;
    },
    placeholderImg() {
      return this.companyPlaceholder;
    },
    entityLoaded() {
      return this.$store.getters['subject/hasPerson'];
    },
  },
  mounted() {
    if (!this.$store.getters['auth/isLogged'] || !this.entityLoaded) {
      return;
    }

    this.fetchData();
  },
  watch: {
    entityLoaded(val, oldVal) {
      console.log('watch entityLoaded', val, oldVal);
      if (val !== oldVal && val) this.fetchData();
    },
  },
  methods: {
    fetchData() {
      this.$store.dispatch('subject/loadUserProfiles', 'person');
    },
    closeModal() {
      this.$bvModal.hide(this.id);
    },
    profilesImgStyle(img) {
      const imgUrl = img.length
        ? createPublicUri(img)
        : this.placeholderImg;
      return `background-image: url(${imgUrl});`;
    },
    changeProfile(profile) {
      if (isNotEmpty(profile) && profile.status !== 'ACTIVE') return;
      // console.log('preloader pre', this.$store.getters.isPreloading);
      console.debug('ModalProfileSwich setpreloader', true);
      this.$store.dispatch('setPreloader', true);
      // console.log('preloader post', this.$store.getters.isPreloading);
      this.$store.dispatch('subject/setCurrentProfile', profile).then(() => {
        console.debug('ModalProfileSwich loaded setpreloader', false);
        this.$store.dispatch('setPreloader', false);
        this.$apolloProvider.defaultClient.cache.reset();
      },
      (error) => {
        console.log('Error in change profile', error);
        console.debug('ModalProfileSwich error setpreloader', false);
        this.$store.dispatch('setPreloader', false);
      });
      this.closeModal();
      if (!this.$route.path.includes('dashboard-utente')) {
        this.routeTo('/dashboard-utente');// not the best way but it works.......?
      }
    },
    routeTo(path) {
      this.$router.replace(path);
    },
    profileClassesCompanies(profile) {
      const baseClasses = 'card card--profile cursor-pointer';
      const currentProfile = this.$store.getters['subject/currentProfile'];
      if (isNotEmpty(profile) && isNotEmpty(currentProfile) && currentProfile.childEntity.entityId === profile.childEntity.entityId) {
        return `${baseClasses} card--profile--active`;
      }
      if (profile && profile.status !== 'ACTIVE') {
        return `${baseClasses} card--profile--disabled`;
      }
      return baseClasses;
    },
    selectIcon(profile) {
      if (isNotExist(profile) && this.$store.getters['subject/isPersonalProfile']) {
        return ['fas', 'check-circle'];
      }
      const currentProfile = this.$store.getters['subject/currentProfile'];
      if (isNotEmpty(profile) && isNotEmpty(currentProfile) && currentProfile.childEntity.entityId === profile.childEntity.entityId) {
        return ['fas', 'check-circle'];
      }
      return ['fas', 'circle'];
    },
  },
};
</script>

<style scoped>

</style>
